import { theme } from '../../../styles'
import { ISVGIcon, SVGIcon } from '../../atoms/SVGIcon'

export const animatedVars = `
--colored-circle-color: ${theme.cssVars.darkestBlue};
--colored-circle-scale: 1;

--dashed-circle-scale: 100%;

--circle-line-color: ${theme.cssVars.darkestBlue};
--circle-line-scale: 1;

--play-fill-color: ${theme.cssVars.darkestBlue};
--play-stroke-color: ${theme.cssVars.white};
`

export function VideoIndicator({ className, ...props }: Omit<ISVGIcon, 'name'>): JSX.Element {
  return (
    <>
      <SVGIcon
        name="play-circle-hover"
        className={`VideoIndicator ${className || ''}`}
        secondaryColor={theme.cssVars.white}
        {...props}
      />

      <style global jsx>{`
        .VideoIndicator {
          --transition-timing: 0.3s;
          --colored-circle-color: ${theme.cssVars.white};
          --colored-circle-scale: 0.8;

          --dashed-circle-scale: 0;

          --circle-line-color: ${theme.cssVars.white};
          --circle-line-scale: 1.6;

          --play-fill-color: ${theme.cssVars.white};
          --play-stroke-color: ${theme.cssVars.darkBlue};
        }
      `}</style>
    </>
  )
}
